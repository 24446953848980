.header {
  padding: 40px 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* BC-103 */
@media screen and (max-width: 991px) {
  .header {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* .bc__logo-container {
  display: flex;
  padding: 40px 0 30px 25px;
}

.bc__logo {
  width: 150px;
} */

.profileheader {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 90%;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.profileheader__avatar {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  margin-top: -85px;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  padding: 5px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  object-fit: cover;
}

.profileheader__avatar-letter {
  display: flex;
  justify-content: center;
  margin-top: -6%;
  color: #000;
  text-align: center;
  font-weight: 700;
  font-size: 85px;
  line-height: 1.2;
  font-style: normal;
}

.profileheader__avatar--small {
  width: 50px;
  height: 50px;
  object-fit: cover;
  font-size: 36px;
  margin-top: 1rem;
  border-radius: 50%;
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.profileheader__username {
  margin: 0;
  color: #000;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.2;
  font-style: normal;
}

.profileheader__useraffiliation {
  margin: 0;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;
  font-style: normal;
}

.profileheader__buttons {
  display: flex;
  gap: 40px;
  margin-bottom: 15px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  /* .bc__logo {
    width: 125px;
  } */

  .profileheader__avatar {
    margin-top: -40px;
    width: 85px;
    height: 85px;
  }

  .profileheader__avatar-letter {
    width: 85px;
    height: 45px;
    font-size: 45px;
    line-height: 45px;
  }

  .profileheader__username {
    font-size: 24px;
  }

  .profileheader__affiliation {
    font-size: 18px;
  }

  .profileheader__buttons {
    gap: 10px;
  }
}
