.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.modal__content {
  background-color: #ffffff;
  width: 80%;
  max-height: 85vh;
  /* height: fit-content; */
  border-radius: 20px;
  padding: 2%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  overflow-y: auto;
}

.modal__close {
  background-image: url("../../images/close.svg");
  width: 25px;
  height: 25px;
  position: relative;
  left: 48%;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border-radius: 4px;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
}

.modal__buttons {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal__buttons-polling {
  display: flex;
  justify-content: left;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal__close:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.modal__heading {
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  margin: 0;
}

.modal__subheading {
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.modal__resources {
  display: flex;
  width: 80%;
  gap: 20%;
}

.modal__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}

.modal__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 80%;
  padding-top: 15px;
  padding-bottom: 5px;
}
.modal__label-align {
  align-items: center;
}
.modal__input {
  width: 100%;
  height: fit-content;
  border: 1px solid grey;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.06);
  padding: 15px;
  font-size: 14px;
  line-height: 1.2;
}

.modal__input::placeholder {
  color: black;
}

.textarea {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: black;
}

.textarea::placeholder {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: black;
}

.file-input {
  background: none;
  border: none;
  padding-left: 0px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal__input {
    width: 80%;
  }

  .modal__close {
    top: 12px;
    left: 44%;
  }
}
