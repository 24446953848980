.toggle {
  cursor: pointer;
  background: transparent;
  border: none;
}

.menu-nav {
  list-style: none;
  position: absolute;
  background: transparent;
  width: 0;
  overflow: hidden;
  max-width: 150px;
  z-index: 9;
  font-size: 18px;
  transition: transform ease-in-out 0.2s;
}

.menu-nav.show-menu {
  width: 50%;
  transform: translateX(-45px);
  background: white;
  width: fit-content;
  /* height: fit-content; */
  padding: 6px 8px 6px 10px;
  border-radius: 4px;
  border: none;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
}

.link-item {
  padding: 10px;
}

.link-text {
  color: var(--primary-main);
  font-size: 20px;
  line-height: 1.2;
  cursor: pointer;
}

.link-disabled {
  color: var(--primary-disabled);
  pointer-events: none;
  font-size: 20px;
  line-height: 1.2;
  cursor: not-allowed;
}

/* button {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 20px;
} */
/* .navbar .toggle {
  position: fixed;
  left: 30px;
  top: 40px;
  cursor: pointer;
  background: transparent;
  border: none;
}
.menu-nav {
  list-style: none;
  position: absolute;
  background: #fff;
  left: 0;
  width: 0;
  overflow: hidden;
  max-width: 290px;
  z-index: 9;
  font-size: 18px;
  box-shadow:
    0 10px 15px -3px rgb(46 41 51 / 8%),
    0 4px 6px -2px rgb(71 63 79 / 16%);
  transform: translateX(-100px);
  transition: transform ease-in-out 0.2s;
}

.menu-nav.show-menu {
  width: 100%;
  transform: translateX(0px);
}
.menu-nav li a,
.menu-nav li span {
  display: block;
  padding: 1rem;
}
 */
