.main {
  padding: 0 40px 0;
}

/* BC-103 */
@media screen and (max-width: 991px) {
  .main {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.main__admin-forms {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.main__admin-button {
  cursor: pointer;
}

.main__admin-button:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.main__headers {
  padding-top: 30px;
}

.main__card-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
}
