.pre-homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.homepage__logo {
  width: 350px;
  padding: 50px;
}

.homepage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 40px;
  /* gap: 20px; */
  height: fit-content;
}

.container {
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.homepage__login-form {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  align-items: center;
}

.homepage__form-inputs {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 10%;
  /* justify-content: center; */
  width: 100%;
}

.homepage__button-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .homepage__logo {
    width: 250px;
    padding: 30px;
  }

  .homepage {
    width: 200px;
  }

  .homepage__login-form {
    gap: 10px;
    width: 200px;
  }

  .homepage__form-inputs {
    margin-left: 22%;
  }
}
