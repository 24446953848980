.search-results {
  position: absolute;
  width: fit-content;
  background-color: white;
  border: none;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  right: 0px;
  bottom: 0px;
  transform: translate(0, 100%);
}

.search-result {
  border-bottom: #cecaca 1px solid;
  padding: 5px;
}

.search-result:hover {
  background-color: var(--primary-light);
}

.search-anchor {
  display: flex;
  gap: 20px;
  position: relative;
}

.search__input {
  width: 100%;
  height: fit-content;
  border: 1px solid grey;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.06);
  padding: 15px;
  margin-top: 10px;
}
