.response {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
}

.reply {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
}

.response__info-container {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
}

.response__subheader1-container {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
}

.test_response-stuff {
  display: flex;
  align-items: center;
}

.response__subheader2-container {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  align-items: flex-start;
  width: fit-content;
}

.responsecard__responseavatar {
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  padding: 5px;
  background: #fff;
  border: 3px solid var(--primary-main);
  object-fit: cover;
}

.responsecard__avatar-letter {
  display: flex;
  justify-content: center;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  font-style: normal;
}

.responsecard__timedate-response-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}

.responsetext {
  margin-right: 20px;
  text-align: left;
  max-width: 80%;
}

.response__icon-container {
  display: flex;
  align-items: center;
  justify-items: center;
  padding-right: 20px;
  gap: 10px;
}

.reply__icon-container {
  display: flex;
  align-items: flex-end;
}

.reply__icon-container > button {
  flex-basis: 50%;
  align-self: flex-start;
  text-align: start;
}
.reply__icon-container > button:first-of-type {
  margin-left: 2%;
}
.reply__icon-container > button:last-of-type {
  margin-left: -2%;
}

.likes {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  padding-left: 20px;
}
