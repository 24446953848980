.footer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: "Cabinet-Grotesk", Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: #000;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-button {
  min-width: 100px;
  min-height: 42px;
  width: fit-content;
  background-color: transparent;
  color: var(--primary-main);
  margin-top: 10px;
  border: none;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
}
