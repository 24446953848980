:root {
  --primary-main: #b4297e;
  --primary-light: #fabcdb;
  --primary-disabled: #746973;
  --secondary-main: #2fbcb0;
  --error-main: #f44336;
}

.App {
  text-align: center;
  width: 100%;
  height: 100%;
  background-image: url("../../images/Desktop_BG.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  min-width: 100px;
  min-height: 42px;
  width: fit-content;
  background-color: var(--primary-main);
  color: white;
  margin-top: 10px;
  padding: 6px 8px 6px 10px;
  border-radius: 4px;
  border: none;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
}

.button[type="radio"] {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  box-shadow: none;
  margin: 5px;
}

.button:disabled {
  background-color: var(--primary-disabled);
}

.button-stretch {
  width: 90%;
  font-weight: 600;
}

.button-outline {
  color: var(--primary-main);
  background-color: white;
  border: 2px solid var(--primary-main);
  cursor: pointer;
  font-weight: 600;
}

.button-no_outline {
  min-width: 100px;
  min-height: 42px;
  width: fit-content;
  background-color: white;
  color: var(--primary-main);
  margin-top: 10px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
}

.button-secondary {
  background-color: var(--secondary-main);
  font-weight: 600;
}

.button .secondary .outline {
  border: 2px solid var(--secondary-main);
  color: var(--secondary-main);
  background-color: white;
}

.button-error {
  background-color: var(--error-main);
  width: 90%;
  font-weight: 600;
}

.button-icon {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.button-icon-outline {
  min-height: 42px;
  width: fit-content;
  padding: 6px 8px 6px 10px;
  border-radius: 4px;
  border: 2px solid var(--primary-main);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
}

.button-icon-outline-disabled {
  pointer-events: none;
  min-height: 42px;
  width: fit-content;
  padding: 6px 8px 6px 10px;
  border-radius: 4px;
  border: 2px solid var(--primary-disabled);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
}

.button-floating {
  position: fixed;
  bottom: 0px;
  right: 50%+20px;
}

.radio-buttons {
  display: flex;
  justify-content: center;
}

.radio-description {
  display: flex;
  align-items: center;
  margin: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.2;
}

.button-group {
  display: flex;
  gap: 20px;
}

.left-aligned {
  justify-content: flex-start;
}

.box {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  margin: 20px auto;
  min-height: fit-content;
  min-width: 450px;
  flex-direction: column;
}

.table__headers {
  text-align: left;
  padding: 0 10px;
}

.table__values {
  text-align: left;
  padding: 0 10px;
  font-weight: 500;
}

.table__center {
  text-align: center;
}

.truncation {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-container {
  display: flex;
  gap: 15px;
}

.icon {
  width: 22px;
  max-height: 22px;
}

.icon-medium {
  width: 30px;
  max-height: 30px;
}

.icon-enlarge {
  width: 40px;
  max-height: 40px;
}

.icon-fitlarge {
  width: 40px;
  max-height: 65px;
}

.icon-max {
  width: 75px;
  max-height: 75px;
}

.icon-file {
  width: 75px;
  height: 75px;
}

.icon-medium-poll {
  width: 30px;
  max-height: 30px;
}

.icon-pollingbadge {
  width: 35px;
  max-height: 35px;
  margin-right: 5px;
}

/* Adding in CSS for EventCard, UserCard, TopicCard, DiscussionQuestion Card */
.card {
  position: relative;
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.card__text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0 10px 20px;
  justify-content: space-between;
}

.card__text {
  margin: 2px 0 5px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}

.card__text-title {
  margin: 5px 0 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2;
  word-break: break-word;
  white-space: normal;
}

/* Left-align text on event topic and discussion question cards */
.card__text-container .card__text-title {
  text-align: left;
}

.card__text-date {
  margin: 2px 60px 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.5);
  text-align: left;
  text-wrap: wrap;
  /* max-width: 110%; */
}

.card__statusicons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0px 20px 0 0;
  padding-bottom: 15px;
}

.card__statusicons-container-polling {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__text-status {
  padding: 6px;
  color: #2fbcb0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  border-color: #2fbcb0;
  border-radius: 30px;
  border: 1px solid;
  border-width: 2px;
}

/* Resource Card CSS */
.resourcecard {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
}

.resourcecard-container {
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: space-between; */
  /* padding-left: 20px; */
  /* padding-top: 15px; */
  margin: 20px;
}

.resourcecard-icon-container {
  display: flex;
  justify-content: flex-end;
  margin: 0px 20px 0 0;
  /* margin-top: 5px; */
  /* padding-left: 20px; */
}

/* Confirmation Modal CSS */
.modal__confirm-container {
  width: 80%;
  height: 35%;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modal__confirm-message {
  font-size: 20px;
  line-height: 1.2;
  color: #000000;
  font-weight: 600;
  font-style: normal;
  text-align: center;
}

.modal__confirm-cancel {
  margin: 0;
  padding: 0;
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.2;
  color: #2fbcb0;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  cursor: pointer;
}

.modal__confirm-cancel:hover {
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

/* Resource Popup Viewer Modal */
.modal__container {
  position: relative;
  background-color: #fff;
  width: 90%;
  margin: 0 10px 0 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.modal__container-pdf {
  position: relative;
  background-color: #fff;
  max-height: 90%;
  margin: 0 10px 0 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
}

.modal__subcontainer {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.modal__popup-close {
  background-image: url("../../images/close.svg");
  width: 25px;
  height: 25px;
  position: relative;
  /* left: calc(45%); */
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  border-radius: 4px;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 3px 0px rgba(0, 0, 0, 0.14),
    0px 3px 5px -2px rgba(0, 0, 0, 0.2);
}

.modal__popup-image {
  width: 50%;
}

.modal__popup-close:hover {
  opacity: 0.6;
  transition: opacity 0.2s linear;
}

.modal__videoresource {
  width: 90%;
  margin-bottom: 20px;
}

.modal__pdfresource {
  width: 90%;
  max-height: 90%;
}

.modal__poll {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 80%;
  padding-top: 15px;
  padding-bottom: 5px;
}

.modal__options {
  display: flex;
}

.modal__polloptions {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 5px;
  margin: 20px;
}

/* Brightly Connect Header and Nav for all pages */
.bc__logo-container {
  display: flex;
  justify-content: space-between;
  padding: 40px 30px 30px 25px;
}

.bc__logo {
  width: 150px;
}

/* Header CSS for Event, Topic, DiscussionQuestion and Discussion Board Pages */

.pageheader {
  padding: 0px 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageheader__info-container {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 20px;
}

.pageheader__subheader1-container {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.pageheader__line {
  display: block;
  margin: 15px auto 0;
  border-bottom: 3px solid var(--primary-disabled);
  width: calc(100% - 7%);
}

.pageheader__subheader2-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 15px;
}

.pageheader__info_container {
  text-align: left;
  padding-left: 20px;
  padding-top: 15px;
}

.pageheader__info-container-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageheader__statusicons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 40px; */
  margin: 40px 20px 0 0;
  padding-bottom: 15px;
}

.pageheader__event-logo {
  max-width: 150px;
  max-height: 200px;
}

.pageheader__icon-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  /* margin-top: -5px; */
  padding-right: 40px;
  padding-bottom: 5px;
  gap: 10px;
}

/* Main Page Sections */
.main {
  padding: 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__page-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  margin-top: 30px;
  width: 90%;
  /* position: relative; */
  /* width: 90%;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
  padding-bottom: 10px;
  margin-left: 33px; */
}

.main__admin-forms {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.main__card-section {
  margin-top: 30px;
  width: 100%;
}

.main__poll-section {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  opacity: 0.9;
  background: #fff;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 10px;
}

.polling__info-container {
  position: relative;
  width: 90%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.polling_options {
  text-align: left;
}

.polling_text {
  margin-top: 12%;
  margin-bottom: 12%;
}

.barchart {
  width: 90%;
}

.main__headers {
  margin-top: 0px;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  /* .App {
    background-image: url("../../images/Tablet_BG.svg");
  } */

  .button {
    margin-top: 0px;
    font-size: 12px;
  }

  .button-outline {
    font-size: 12px;
  }

  .button-stretch {
    font-size: 12px;
  }

  .card__text {
    font-size: 14px;
  }

  .card__text-title {
    font-size: 16px;
  }

  .card__text-date {
    font-size: 14px;
  }

  .card__text-status {
    font-size: 14px;
  }

  .modal__logout-message {
    font-size: 14px;
  }

  .modal__logout-cancel {
    font-size: 14px;
  }

  .icon {
    width: 18px;
  }

  .icon-medium {
    width: 16px;
  }

  .icon-enlarge {
    width: 20px;
  }

  .icon-file {
    width: 40px;
  }

  .icon-medium-poll {
    width: 26px;
  }

  .icon-container {
    gap: 10px;
  }

  .card__statusicons-container {
    padding-bottom: 10px;
  }

  .bc__logo {
    width: 125px;
  }

  .pageheader__icon-container {
    padding-right: 16px;
  }

  .pageheader__event-logo {
    max-width: 80px;
  }

  .pageheader__statusicons-container {
    margin: 30px 18px 0 0;
  }

  .pageheader__subheader1-container {
    padding-top: 5px;
  }

  .pageheader__subheader2-container {
    padding-left: 20px;
  }

  .icon-fitlarge {
    max-height: 40px;
  }
}

/* BC-103: Optimize the container width on mobile/tablet */
@media screen and (max-width: 991px) {
  .pageheader,
  .main {
    padding-left: 5px;
    padding-right: 5px;
  }
}
